import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { fetchPlantVisibilityHistory } from '../actions';
import {
  getPlantVisibilityByType,
  getPlantVisibilityStateByType
} from '../selectors';

function useGroupHistory(groupId, { fetch = false } = {}) {
  const dispatch = useDispatch();

  const args = { groupId, visibilityType: 'plantVisibilityHistory' };
  const groupHistory = useSelector(state => getPlantVisibilityByType(state, args));
  const { loading, error, lastFetch } = useSelector(state => getPlantVisibilityStateByType(state, args)) || {};

  const fetchHistory = useCallback(() => {
    if (!groupId) return;
    dispatch(fetchPlantVisibilityHistory({ id: groupId }));
  }, [groupId, dispatch]);

  useEffect(() => {
    if (fetch && groupId) {
      fetchHistory();
    }
  }, [fetch, groupId]);

  return { loading, error, lastFetch, groupHistory, fetchHistory };
}

export default useGroupHistory;
