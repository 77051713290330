import { useCallback } from 'react';
import { Dropdown, Form, Row, Col, Button } from 'react-bootstrap';
import { IoIosCalendar } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormModal from '../../../components/FormModal';
import { convertToTimestamp } from '../../../components/charts/BaseChart';
import useGroupSchedule from '../hooks/useGroupSchedule';

const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
const getDuration = (start, end) => {
  const diffTime = Math.abs(end - start);
  return Math.floor(diffTime / oneDayInMilliseconds);
};
const getEndDate = (start, duration) => {
  const startTimestamp = convertToTimestamp(start);
  const endTimestamp = startTimestamp + (duration * oneDayInMilliseconds);
  // Can't find a more concise way to do this. Stack Overflow has bananas long options. Wait until
  // handling timezones properly to add complexity.
  return new Date(endTimestamp).toISOString().substring(0, 10);
};

function UpdateScheduleModal({
  groupId,
  deviceId,
  scheduleId,
  start,
  end,
  comments,
  header,
  as='dropdown',
}) {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      start: new Date(start).toISOString().substring(0, 10),
      duration: getDuration(start, end),
      comments: comments,
    }
  });
  const { loading: submitting, updateSchedule } = useGroupSchedule(groupId, deviceId, scheduleId);

  const onSubmit = useCallback(async (data) => {
    const {
      start,
      duration,
      comments,
    } = data;
    const end = getEndDate(start, duration);
    await updateSchedule({
      start,
      end,
      comments,
    });
  }, [updateSchedule]);

  const renderForm = useCallback(() => {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
          <Form.Label column sm="3">{t('components.organisations.update-schedule-modal.start-date')}</Form.Label>
          <Col sm="9">
            <Form.Control
              type="date"
              required={true}
              min={new Date().toISOString()}
              {...register('start', {
                required: true,
              })}
              style={{ width: '25%', minWidth: '10em' }}
            />
            <Form.Text muted>{t('components.organisations.update-schedule-modal.select-date')}</Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">{t('components.organisations.update-schedule-modal.duration-days')}</Form.Label>
          <Col sm="9">
            <Form.Control
              type="number"
              step="1"
              required={true}
              min={1}
              {...register('duration', {
                valueAsNumber: true,
                required: true,
              })}
              style={{ width: '25%', minWidth: '8em' }}
            />
            <Form.Text muted>{t('components.organisations.update-schedule-modal.estimate-complete')}</Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">{t('components.organisations.update-schedule-modal.add-comment')}</Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              {...register('comments')}
            />
            <Form.Text muted>{t('components.organisations.update-schedule-modal.see-who-mention')}</Form.Text>
          </Col>
        </Form.Group>
      </Form>
    );
  }, [onSubmit, formState]);

  return (
    <FormModal
      header={header}
      form={renderForm()}
      size="md"
      valid={!submitting && formState.isValid}
    >
      {as === 'dropdown' ?
        <Dropdown.Item>
          <IoIosCalendar size="1.2em" /> <span>{header}</span>
        </Dropdown.Item> :
        <Button size="sm" variant="outline-secondary">
          <IoIosCalendar size="1.2em" /> <span>{header}</span>
        </Button>
      }
    </FormModal>
  );
}

export default UpdateScheduleModal;
