import React, { useState, useCallback, useLayoutEffect, useRef, useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import BaseChartToolbar from './BaseChartToolbar';

import './baseChart.scss';
import { getTimezoneOffset } from '../values/Timezone';
import { oneDay, oneHour } from '../../lib/utils';

// helper function to get eChart compatible symbol paths from simple icons
export function getSymbolOfIcon(Icon) {
  // find the icon path node with the draw command
  const path = Icon().props.children.find(({ type, props={} }) => {
    return type === 'path' && props.d;
  });
  return path && `path://${path.props.d}`;
}

export function convertToTimestamp(time) {
  return (new Date(time)).getTime();
}

function kebabCase(text="undefined") {
  return text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z-]/g, '');
}

const t = i18n.t;

// set echarts to use <canvas> so that users can download a .png or .jpg image
// if it is set to (of defaults to) <svg> then the saved file will be .svg
// which most people don't tend to like
const echartOpts = { renderer: 'canvas' };
const echartStyle = { height: 400 };

// default options for all charts
// (requires more information than this to display correctly)
export const defaultOptions = (
  {
    grid: {
      top: 55,
      bottom: 90,
      left: 55,
      right: 72,
    },
    legend: {
      type: 'scroll',
      top: 15,
      left: 50,
      right: 50,
    },
    tooltip: {
      trigger: 'axis',
    },
    dataZoom: {
      id: 'xAxisMain',
      type: 'slider',
      height: 40,
      bottom: 10,
      filterMode: 'filter',
      xAxisIndex: 0,
      // minimum view is an hour
      minValueSpan: oneHour,
    },
    xAxis: {
      id: 'xAxisMain',
      type: 'time',
      name: `${t('time')} (${getTimezoneOffset()})`,
      nameLocation: 'center',
      nameGap: 22,
      axisLabel: {
        // ensure labels don't bump into each other
        padding: [0, 4],
      },
    },
    color: ['#2bae23', 'black', '#7cb5ec'],
    animation: false,
  }
);

export default function useBaseChart(computeInitialOptionsCallback, {
  // allow a custom BaseCart component to be passed
  CustomBaseChart = BaseChart,
}={}) {

  // get initialOptions
  const [initialOptions] = useState(() => computeInitialOptionsCallback() || {});

  // define the chart and options as mutable references
  const eChartsReact = useRef(null);

  const getChart = useCallback(() => {
    return eChartsReact.current && eChartsReact.current.getEchartsInstance();
  }, [eChartsReact]);

  // update all write functions for the chart when it changes
  const [useChartEvent, useChartUpdateEffect, useChartUpdateOnChartEvent] = useMemo(() => {
    const chartEvent = createChartEventCallback(getChart);
    return [
      chartEvent,
      createChartUpdateEffect(getChart),
      (eventName, eventHandler) => {
        // wrap chart event handler with a chart update
        chartEvent(eventName, (...eventArgs) => {
          updateChart(getChart, chartOptions => eventHandler(chartOptions, ...eventArgs));
        });
      },
    ];
  }, [getChart]);

  // save custom event handlers in a reference to be executed when appropriate
  const customEventHandlersRef = useRef([]);
  const useChartUpdateOnCustomEvent = (eventName, eventHandler) => {
    // use layout effect to be in time for chart layout effects
    useLayoutEffect(() => {
      const changeSet = [eventName, eventHandler];
      // replace current so changes can be seen in BaseChart
      customEventHandlersRef.current = [...customEventHandlersRef.current, changeSet];
      return () => {
        customEventHandlersRef.current = customEventHandlersRef.current
          .filter(item => item !== changeSet);
      };
    }, [eventName, eventHandler]);
  };

  return [
    // return wrapped BaseChart component in an unchanging reference
    useRef(props => (
      <CustomBaseChart
        // add default getChart
        getChart={getChart}
        eChartsReact={eChartsReact}
        options={initialOptions}
        useChartUpdateEffect={useChartUpdateEffect}
        useChartEvent={useChartEvent}
        customEventHandlersRef={customEventHandlersRef}
        {...props}
      />
    )).current,
    // pass optional helpers to use
    {
      getChart,
      useChartUpdateEffect,
      useChartEvent,
      useChartUpdateOnChartEvent,
      useChartUpdateOnCustomEvent,
    },
  ];
}

function updateChart(getChart, handler) {
  const chart = getChart();
  if (chart) {
    // the initial options object is not available here. why?:
    // it is important to not combine the logic between the initial options
    // and chart options. it leads to confusion, particularly if the current
    // chart options have been modified outside of our component logic:
    // we cannot reasonably and 100% accurately track the options state
    // outside of the eCharts instance, and to do so is an anti-pattern.
    // we use the eCharts option state here for consistency

    // note: spreading the current chart options may not be a safe operation.
    // for example, when setting xAxis min/max values, spreading the current
    // chart options will attempt to set rangeStart/rangeEnd values, which
    // may conflict with the options you are intending to set.
    // therefore, you should usually only pick the exact props from the
    // chart options that you need
    const partialUpdate = handler(chart.getOption());
    // only process an update the if a change is available
    if (partialUpdate) {
      // send a partial update to the chart (without causing future events)
      chart.setOption(partialUpdate, {
        notMerge: false,
        lazyUpdate: false,
        silent: true,
      });
    }
  }
}

const createChartUpdateEffect = getChart => {
  // if get a handler was passed, use it as dependencies,
  // as it might be a callback hook
  return (handler, dependencies=[handler]) => {
    useLayoutEffect(() => {
      updateChart(getChart, handler);
    }, dependencies);
  };
};

const createChartEventCallback = getChart => {
  // add hook to handle the event handling effects of the chart
  // eventName is case insensitive for readability
  // if get a handler was passed, use it as dependencies,
  // as it might be a callback hook
  return (eventName, eventHandler, dependencies=[eventHandler]) => {
    // only change (wrapped) event handler if the dependencies of it change
    const wrappedEventHandler = useCallback(eventHandler, dependencies);

    // when the event changes (or the chart or event name)
    // - add the new event handler, and
    // - return a cleanup function for the handler
    useLayoutEffect(() => {
      const chart = getChart();
      if (chart) {
        chart.on(eventName.toLowerCase(), wrappedEventHandler);
        return () => chart.off(eventName.toLowerCase(), wrappedEventHandler);
      }
    }, [getChart, eventName, wrappedEventHandler]);
  };
};

export function BaseChartBody({
  namespace,
  dateRange,
  setDateRange,
  maximumViewedDateRange,
  samples = [],
  eChartsReact,
  options,
  getChart,
  useChartUpdateEffect,
  useChartEvent,
  customEventHandlersRef,
  setRenderId,
  setDateThrottleMode = 'during',
  style = {},
}) {

  const { t } = useTranslation();
  const setDateThrottleModeDuring = setDateThrottleMode === 'during';

  // collect custom event handlers into specific event handler lists
  const {
    resize: onResizeChanges,
    dateRange: onDateRangeChanges,
  } = useMemo(() => {
    return customEventHandlersRef.current.reduce((acc, [eventName, eventHandler]) => {
      acc[eventName] = acc[eventName] || [];
      acc[eventName].push(eventHandler);
      return acc;
    }, {});
  }, [customEventHandlersRef.current]);

  // handle window resize chart updates by hooking into the chart size changes
  // *after* the chart has rendered with a changed width.
  // this avoids having to wait enough time in a setTimeout or similar
  // to ensure the changes are applied on the right size chart
  const chartWidth = useRef(null);
  useChartEvent('rendered', () => {
    const chart = getChart();
    if (chart && onResizeChanges && onResizeChanges.length) {
      const oldWidth = chartWidth.current;
      const newWidth = chart.getWidth();
      if (oldWidth !== newWidth) {
        // set the current width
        chartWidth.current = newWidth;
        // trigger update only if there was an oldWidth that was rendered
        if (oldWidth) {
          // handle all given updates, after chart has been resized
          onResizeChanges.forEach(onResizeChange => {
            updateChart(getChart, onResizeChange);
          });
        }
      }
    }
  }, [onResizeChanges]);

  // handle date range changes
  const chartDateRange = useRef([]);
  useChartEvent('rendered', () => {
    const chart = getChart();
    if (chart && onDateRangeChanges && onDateRangeChanges.length) {
      const dataZoom = chart.getOption().dataZoom.find(({ id }) => id === 'xAxisMain');
      const oldRange = chartDateRange.current;
      const newRange = [dataZoom.startValue, dataZoom.endValue];
      if ((oldRange[0] !== newRange[0]) || (oldRange[1] !== newRange[1])) {
        // set the current width
        chartDateRange.current = newRange;
        // trigger update only if there was an oldRange that was rendered
        if (oldRange[0]) {
          // handle all given updates, after chart has been rendered
          onDateRangeChanges.forEach(onDateRangeChange => {
            updateChart(getChart, onDateRangeChange);
          });
        }
      }
    }
  }, [onDateRangeChanges]);

  // set callback to handle throttling of the setDateRange function
  const throttledSetDateChangeLastRun = useRef(Date.now());
  const throttledSetDateChangeLastTimeout = useRef();
  const throttledSetDateChange = useCallback(opts => {
    // set change if last change was processed at least a little while ago
    const delay = 1000;
    // cancel any running timeouts
    clearTimeout(throttledSetDateChangeLastTimeout.current);
    // start timeout immediately but let changes be made to the args before they are applied
    const timeout = setTimeout(function() {
      if (Date.now() - throttledSetDateChangeLastRun.current >= delay) {
        setDateRange(opts);
        throttledSetDateChangeLastRun.current = Date.now();
      }
    }, delay - (
      // fire during changes occasionally if asked to
      setDateThrottleModeDuring
        ? (Date.now() - throttledSetDateChangeLastRun.current)
        : 0
    ));
    // add timeout reference to be cleared by any subsequent calls
    throttledSetDateChangeLastTimeout.current = timeout;
  }, [
    setDateRange,
    throttledSetDateChangeLastRun,
    throttledSetDateChangeLastTimeout,
    setDateThrottleModeDuring,
  ]);

  // update the xAxis range if required
  const updateXaxis = useCallback(() => {
    return {
      xAxis: {
        id: 'xAxisMain',
        axisLabel: {
          // ensure labels don't bump into each other
          padding: [0, 4],
          formatter: value => {
            const timeRange = dateRange.endTime.valueOf() - dateRange.startTime.valueOf();
            if (timeRange < 3 * oneDay) {
              // display date time without seconds
              return new Date(value).toLocaleTimeString(undefined, {
                day: 'numeric',
                month: 'short',
                hour: 'numeric',
                minute: 'numeric',
              });
            }
            else {
              // format just date with a year
              return new Date(value).toLocaleDateString();
            }
          },
        },
        min: maximumViewedDateRange.startTime,
        max: maximumViewedDateRange.endTime,
      },
    };
  }, [
    dateRange.startTime,
    dateRange.endTime,
    maximumViewedDateRange.startTime,
    maximumViewedDateRange.endTime,
  ]);

  // update the dataZoom if required
  const updateDataZoom = useCallback(() => {
    if (
      (dateRange.updateFromNamespace !== namespace) &&
      (dateRange.startTime && dateRange.endTime)
    ) {
      return {
        dataZoom: {
          id: 'xAxisMain',
          startValue: dateRange.startTime,
          endValue: dateRange.endTime,
        },
      };
    }
  }, [
    namespace,
    dateRange.updateFromNamespace,
    dateRange.startTime,
    dateRange.endTime,
  ]);

  // update the xAxis timezone if required
  const updateTimezone = useCallback(() => {
    return {
      xAxis: {
        id: 'xAxisMain',
        type: 'time',
        name: `${t('time')} (${getTimezoneOffset()})`,
      }
    };
  }, [getTimezoneOffset()]);

  // use chart and options to perform partial updates when callbacks change
  useChartUpdateEffect(updateXaxis);
  useChartUpdateEffect(updateDataZoom);
  useChartUpdateEffect(updateTimezone);

  useChartEvent('dataZoom', ({ start, end }) => {
    const {
      startTime: xMin,
      endTime: xMax,
    } = maximumViewedDateRange;
    // throttle the amount of state changes caused at the device sample level
    throttledSetDateChange({
      updateFromNamespace: namespace,
      // set date range in epoch ms
      startTime: Math.round((start / 100) * (xMax - xMin) + xMin),
      endTime: Math.round((end / 100) * (xMax - xMin) + xMin),
    });
  }, [
    namespace,
    maximumViewedDateRange.startTime,
    maximumViewedDateRange.endTime,
    throttledSetDateChange,
  ]);

  // determine when renderId should change using a flag to listen for chart changes
  const saveNextFrameRender = useRef(false);

  // if samples have changed, flag to save the next rendering of the chart
  useLayoutEffect(() => {
    saveNextFrameRender.current = true;
  }, [samples]);


  // flag the chart for update if the date range has changed
  useLayoutEffect(() => {
    saveNextFrameRender.current = true;
  }, [
    dateRange.startTime,
    dateRange.endTime,
    saveNextFrameRender,
  ]);

  useChartEvent('legendSelectChanged', () => {
    // flag change for update
    saveNextFrameRender.current = true;
  }, [saveNextFrameRender]);

  // if the window has rendered with a new size,
  // flag to save the next rendering of the chart
  const chartWidthForRenderId = useRef(null);
  useChartEvent('rendered', () => {
    const chart = getChart();
    if (chart) {
      const oldWidth = chartWidthForRenderId.current;
      const newWidth = chart.getWidth();
      if (oldWidth !== newWidth) {
        // set the current width
        chartWidthForRenderId.current = newWidth;
        // flag change for renderId
        saveNextFrameRender.current = true;
      }
    }
  }, [onResizeChanges]);

  useChartEvent('finished', () => {
    if (saveNextFrameRender.current) {
      // reset flag immediately
      saveNextFrameRender.current = false;
      // set a new render ID to represent to new rendering of the same chart
      setRenderId && setRenderId(renderId => renderId + 1);
    }
  }, [setRenderId, saveNextFrameRender]);

  return (
    <ReactEcharts
      ref={eChartsReact}
      option={options}
      style={{...echartStyle, ...style}}
      opts={echartOpts}
    />
  );
}

function BaseChart({
  namespace,
  deviceId,
  header,
  dateRange,
  setDateRange,
  setDateThrottleMode,
  selectableDateRange,
  maximumViewedDateRange,
  samples,
  hasMore,
  stillFetching,
  options,
  toolbarButtons,
  AboveChart,
  BelowChart,
  getChart,
  eChartsReact,
  useChartUpdateEffect,
  useChartEvent,
  customEventHandlersRef,
  style,
}) {
  // throw dev warnings
  if (!namespace) {
    throw new Error("This chart doesn't have a namespace identifier");
  }
  if (namespace !== kebabCase(namespace)) {
    throw new Error("Namespace identifier should be kebab-case");
  }

  // define shared states

  // set render flag states renderId to cause update
  // be saveNextFrameRender to mutate and wait for a relevant callback
  const [renderId, setRenderId] = useState(0);

  return (
    <Card className="shadow-sm" style={{ marginTop: 15 }}>
      {header && (
        <Card.Header className="pb-2 d-block">
          <Row className="small-gutters align-items-center">
            <Col xs="auto" className="mb-1">
              <h5>{header}</h5>
            </Col>
            <Col xs="auto" className="ml-auto">
              <BaseChartToolbar
                namespace={namespace}
                deviceId={deviceId}
                dateRange={dateRange}
                setDateRange={setDateRange}
                selectableDateRange={selectableDateRange}
                samples={samples}
                hasMore={hasMore}
                stillFetching={stillFetching}
                getChart={getChart}
                renderId={renderId}
                toolbarButtons={toolbarButtons}
              />
            </Col>
          </Row>
        </Card.Header>
      )}
      {AboveChart && <AboveChart />}
      <Card.Body>
        <BaseChartBody
          namespace={namespace}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setDateThrottleMode={setDateThrottleMode}
          maximumViewedDateRange={maximumViewedDateRange}
          samples={samples}
          options={options}
          eChartsReact={eChartsReact}
          getChart={getChart}
          useChartUpdateEffect={useChartUpdateEffect}
          useChartEvent={useChartEvent}
          customEventHandlersRef={customEventHandlersRef}
          setRenderId={setRenderId}
          style={style}
        />
        {BelowChart && <BelowChart />}
      </Card.Body>
    </Card>
  );
}
