import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { IoIosGlobe } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';

import { setLocalAnonymousPreference, submitUserPreferences } from '../modules/user/actions';
import i18n from '../i18n';

export const restrictedDomain = 'movus.com.au';
export const defaultLocale = 'en-AU';
export const defaultCurrency = 'AUD';

const languages = {
  // Edit this object to control the available languages. These map to public/locale/<language>.
  // Base file is AU English i.e. 'en', ignoring the fact that 'en-US' is considered the 'default'.
  // Locales only have their own custom spellings. i18next resolves from multiple places. The gist
  // being that is starts with the current, and then uses the fallback i.e. most specific to least
  // specific. With locale set to 'en-AU', i18next will find e.g. 'Organisation' in that file, then
  // will not find e.g. 'Archive', and will instead fallback to 'en' and find it there. This allows
  // for a single core file with only minor additional language files per locale.
  // Linguistically speaking there are minor differences between e.g. en-AU and en-GB and en-NZ, but
  // for practical purposes we'll assume British style and American style are the only two. If a
  // reason ever exists to be more specific, add e.g. en-NZ and update the mapping in languageMap.
  // https://www.i18next.com/principles/translation-resolution#resolution-order
  'en-AU': { shortName: 'English (AU)', longName: 'English (Australia)' },
  'en-CA': { shortName: 'English (CA)', longName: 'English (Canada)' },
  'en-GB': { shortName: 'English (GB)', longName: 'English (United Kingdom)' },
  'en-IE': { shortName: 'English (IE)', longName: 'English (Ireland)' },
  'en-NZ': { shortName: 'English (NZ)', longName: 'English (New Zealand)' },
  'en-US': { shortName: 'English (US)', longName: 'English (United States)' },

  'es-ES': { shortName: 'Español (ES)', longName: 'Español (España)' },
  'es-MX': { shortName: 'Español (MX)', longName: 'Español (México)' },
};

export function setLanguageAndLocale(locale) {
  if (locale) {
    i18n.changeLanguage(locale);
    // Lowercase is required or moment won't recognise it.
    // Sending an empty string to moment has no effect i.e. the locale stays the same.
    // en-US and es-ES don't exist for import. See src/i18n.js file for rant. Normally they would
    // fallback to en and es respectively and all works fine. When setting the dow below though,
    // moment makes a custom en-us or es-es with the dow settings and then because they don't exist,
    // the locale is borked.
    // Seriously, this library makes stupid design decisions. Many hours wasted on this weirdness.
    if (locale === 'en-US') locale = 'en';
    if (locale === 'es-ES') locale = 'es';
    moment.locale(locale.toLowerCase());
    moment.updateLocale(locale.toLowerCase(), { // Set up Monday as the first day of week for calendar.
      week: {
        dow: 1,
      }
    });
  }
}

export async function updateLanguage(dispatch, user, anonymousLocale, locale) {
  if (anonymousLocale && anonymousLocale !== locale) {
    await dispatch(submitUserPreferences(user, {'preferences:locale': anonymousLocale}))
      .then(async () => {
        await dispatch(setLocalAnonymousPreference('locale', null)); // Reset, to set again on logout
      }).finally(() => {});
  }
}

export function LanguageOptions({ nameType }) {
  return (
    Object.keys(languages).map(lng => (
      <option key={lng} value={lng}>{languages[lng][nameType]}</option>
    ))
  );
}

export function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const setAnonymousLocale = useCallback((locale) => {
    dispatch(setLocalAnonymousPreference('locale', locale));
  }, []);

  return (
    <Form.Group controlId="select-language">
      <Form.Label style={{
        position: 'absolute',
        top: '5px',
        left: '5px'
      }}
      >
        <IoIosGlobe size="1.8em" style={{color: '#666'}} />
      </Form.Label>
      <Form.Control
        as="select"
        onChange={e => setAnonymousLocale(e.target.value)}
        defaultValue={i18n.resolvedLanguage || defaultLocale}
        style={{
          width: '160px',
          paddingLeft: '1.8em'
        }}
      >
        <LanguageOptions nameType={'shortName'} />
      </Form.Control>
    </Form.Group>
  );
}
