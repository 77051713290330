import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { IoIosCog } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { clipRectByRect } from 'echarts/lib/util/graphic';

import {
  convertToTimestamp,
  defaultOptions,
} from '../../../components/charts/BaseChart';

import {
  getActiveGroupId,
  getRootGroupId,
} from '../selectors';

import useGroupSchedule from '../hooks/useGroupSchedule';
import useGroupHistory from '../hooks/useGroupHistory';
import UpdateScheduleModal from '../components/UpdateScheduleModal';

const colours = {
  green: '#00e200',
  yellow: '#f3cb2d',
  red: '#f52727',
  lightGrey: '#cccccc',
  white: '#ffffff',
};
const lineWidth = 3.5;
const swimlaneHeightProportion = 1;
const swimlaneHeightPixels = 80;
const totalVerticalSpacing = 0.4;
const splitVerticalSpacing = totalVerticalSpacing / 2;
const secondsPerDay = 24 * 60 * 60 * 1000;
const daysPerWeek = 7;
const chartWidth = 1600;
const gridMinHeight = swimlaneHeightPixels * 4;
const chartMaxHeight = 1000;
const dropdownToggleWidth = 60;
const dropdownToggleHeight = swimlaneHeightPixels * (swimlaneHeightProportion - totalVerticalSpacing); // Same height as schedule box

function getColourThreshold(conditionOverall) {
  if (conditionOverall > 0.677 && conditionOverall <= 1) return colours.red;
  if (conditionOverall > 0.333 && conditionOverall <= 0.677) return colours.yellow;
  if (conditionOverall >= 0 && conditionOverall <= 0.333) return colours.green;
}

function getTimelineRange(rangeType) {
  // https://stackoverflow.com/a/3894087/8590017
  const nowDate = new Date();
  nowDate.setHours(0, 0, 0, 0);
  const now = convertToTimestamp(nowDate);
  const viewDays = 63;
  const min = now - (viewDays * secondsPerDay);
  const max = now + (viewDays * secondsPerDay);
  return rangeType === 'backwards' ? min : max;
}

// https://stackoverflow.com/questions/73300725/apache-echarts-schedule-style-chart-layout
// https://codepen.io/luke-fnm/pen/NWYOBjE
// https://echarts.apache.org/examples/en/editor.html?c=line-step
// https://jsfiddle.net/IanZhong/h5v3ps1c/18/
// https://stackoverflow.com/questions/51861846/how-to-make-echart-x-axis-type-time-to-display-a-time-period-for-one-day
function getInitialOptions(options=defaultOptions) {
  return {
    ...options,
    xAxis: [
      {
        id: 'xAxisSchedule',
        type: 'time',
      },
      {
        id: 'xAxisHistory',
        type: 'category',
      },
    ],
    legend: {
      data: [],
    },
    yAxis: [
      {
        id: 'yAxisSchedule',
        type: 'category',
        axisLabel: {
          inside: true,
          align: 'left',
          margin: 10,
          fontWeight: 'bold',
        },
      },
      {
        id: 'yAxisHistory',
        type: 'value',
        axisLabel: {
          show: false,
        },
        axisPointer: {
          snap: true
        },
      },
    ],
    series: [
      {
        id: 'yAxisScheduleData',
        yAxisId: 'yAxisSchedule',
        type: 'custom',
      },
      {
        id: 'yAxisScheduleOptionsData',
        yAxisId: 'yAxisSchedule',
        type: 'custom',
      },
    ],
    dataZoom: {
      ...options.dataZoom,
      filterMode: 'none',
      realtime: true,
      throttle: 100,
    }
  };
}

function makeHistoryEntry({
  running,
  conditionOverall,
  equipmentScaleHigh,
  equipmentScaleLow,
  startTimestamp,
  endTimestamp,
}) {
  const point = running ?
    equipmentScaleHigh - splitVerticalSpacing :
    equipmentScaleLow + splitVerticalSpacing;
  return [
    convertToTimestamp(startTimestamp),
    convertToTimestamp(endTimestamp),
    point,
    conditionOverall,
  ];
}

function createHistoryData(groupHistory) {
  if (!groupHistory) return [];
  let equipmentScaleHigh = groupHistory.length;
  let equipmentScaleLow = equipmentScaleHigh - 1;
  const allEquipment = [];
  for (const equipment of groupHistory.values()) {
    const thisEquipment = [];
    for (const [index, history] of equipment.history.entries()) {
      const entryData = {
        running: history.running,
        conditionOverall: history.condition_overall,
        equipmentScaleHigh,
        equipmentScaleLow,
        startTimestamp: history.timestamp,
        endTimestamp: null,
      };
      const thisTimestamp = history.timestamp;
      let nextOne = index + 1;
      if (nextOne < equipment.history.length) {
        entryData.endTimestamp = equipment.history[nextOne].timestamp;
        nextOne += 1;
      } else entryData.endTimestamp = thisTimestamp;
      const thisEntry = makeHistoryEntry({...entryData});
      thisEquipment.push(thisEntry);
    }
    equipmentScaleHigh -= 1;
    equipmentScaleLow -= 1;
    allEquipment.push(thisEquipment);
  }
  return allEquipment;
}

function createScheduleData(groupSchedule) {
  if (!groupSchedule) return {};
  const names = [];
  const schedules = [];
  for (const [index, item] of groupSchedule.entries()) {
    names.push(item.equipment_name);
    for (const entry of item.schedule.values()) {
      const swimlanePlacement = groupSchedule.length - 1 - index;
      schedules.push([
        convertToTimestamp(entry.start),
        convertToTimestamp(entry.end),
        colours[entry.colour],
        item.equipment_name,
        swimlanePlacement,
        item.id, // Device ID
        entry.id, // Schedule ID
        entry.comments,
      ]);
    }
  }
  return {
    names,
    schedules,
  };
}

function OptionsDropdown({
  top,
  left,
  groupId,
  deviceId,
  scheduleId,
  start,
  end,
  comments,
}) {
  const { t } = useTranslation();

  return (
    <Dropdown
      style={{
        position: 'absolute',
        top: top,
        left: left,
      }}
    >
      <Dropdown.Toggle
        variant="outline-dark"
        style={{
          width: `${dropdownToggleWidth}px`,
          height: `${dropdownToggleHeight}px`,
        }}
      >
        <IoIosCog size="1.3em" title="Options" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <UpdateScheduleModal
          groupId={groupId}
          deviceId={deviceId}
          scheduleId={scheduleId}
          start={start}
          end={end}
          comments={comments}
          header={t('screens.organisations.plant-visibility-timeline.edit-schedule')}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

function PlantVisibilityTimeline() {

  const [historyData, setHistoryData] = useState(null);
  const [visualPieces, setVisualPieces] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);
  const [seriesLength, setSeriesLength] = useState(0);
  const [optionsData, setOptionsData] = useState(null);

  const rootGroupId = useSelector(state => getRootGroupId(state));
  const activeGroupId = useSelector(state => getActiveGroupId(state));
  const groupId = activeGroupId || rootGroupId;

  const { groupHistory, fetchHistory } = useGroupHistory(groupId);
  const { groupSchedules, fetchSchedule } = useGroupSchedule(groupId);

  const eChartsReact = useRef(null);
  const containerRef = useRef(null);
  const echartOpts = { renderer: 'canvas' };
  const initialOptions = getInitialOptions();
  const grid = initialOptions.grid;

  let gridHeight = swimlaneHeightProportion * swimlaneHeightPixels;
  if (groupHistory) gridHeight = groupHistory.length * swimlaneHeightPixels;
  if (gridHeight < gridMinHeight) gridHeight = gridMinHeight;
  const chartHeight = gridHeight + grid.top + grid.bottom;
  const gridWidth = chartWidth - grid.left - grid.right;
  const optionsBounds = {
    x: grid.top,
    y: grid.left,
    width: gridWidth,
    height: gridHeight,
  };

  const echartStyle = {
    'maxHeight': chartMaxHeight,
    'height': chartHeight,
    'backgroundColor': colours.white,
  };
  const style = {};
  const getChart = useCallback(() => {
    return eChartsReact.current && eChartsReact.current.getEchartsInstance();
  }, [eChartsReact]);

  useEffect(() => {
    if (groupHistory) {
      const newHistoryData = createHistoryData(groupHistory);
      setHistoryData(newHistoryData);
    }
  }, [groupHistory]);

  useEffect(() => {
    if (groupSchedules) {
      const newScheduleData = createScheduleData(groupSchedules);
      setScheduleData(newScheduleData);
    }
  }, [groupSchedules]);

  useEffect(() => {
    const chart = getChart();
    if (historyData) { // Fine to plot a single point
      chart.setOption({
        series: [
          ...historyData.map((item, index) => {
            return {
              id: `yAxisHistoryLinesData${index + 1}`,
              yAxisId: 'yAxisHistory',
              type: 'line',
              step: 'end',
              lineStyle: { width: lineWidth },
              data: item,
              encode: {
                x: [0, 1],
                y: 2,
              },
            };
          })],
      });
    }
  }, [historyData]);

  useEffect(() => {
    if (historyData) {
      const newVisualPieces = historyData.map((item) => {
        return item.map((x) => {
          return {
            // Visual pieces will crash with a single point
            // var minCoord = colorStops[0].coord - tinyExtent;
            // node_modules/echarts/lib/chart/line/LineView.js:318
            gte: x[0], // Start timestamp
            lte: x[1], // End timestamp
            color: getColourThreshold(x[3]),
          };
        });
      });
      setVisualPieces(newVisualPieces);
    }
  }, [historyData]);

  useEffect(() => {
    const lengthSeries = initialOptions.series.length;
    setSeriesLength(lengthSeries);
  }, []);

  useEffect(() => {
    if (visualPieces) {
      const chart = getChart();
      chart.setOption({
        visualMap: [
          ...visualPieces.map((item, index) => {
            return {
              show: false,
              type: 'piecewise',
              dimension: 0,
              // Can't be determined initially as the rest of the series are created dynamically
              seriesIndex: seriesLength + index,
              pieces: item,
              outOfRange: {
                color: 'black'
              },
            };
          }),
        ],
      });
    }
  }, [visualPieces]);

  useEffect(() => {
    const chart = getChart();
    if (chart) {
      chart.on('rendered', () => {
        const chartOptions = chart.getOption();
        const seriesData = chartOptions.series[0]?.data;
        if (seriesData) {
          const buttonDetails = [];
          for (const seriesDatum of seriesData) {
            const [x, baseY] = chart.convertToPixel({ seriesId: 'yAxisScheduleData' }, [seriesDatum[1], 0]);
            const offset = seriesDatum[4] * swimlaneHeightPixels;
            const y = baseY - offset - (swimlaneHeightPixels - (swimlaneHeightPixels * totalVerticalSpacing)) / 2;
            const target = {
              x,
              y,
              // Clip any dropdown toggle options buttons that are outside the canvas bounds.
              width: dropdownToggleWidth,
              height: dropdownToggleHeight,
            };
            if (clipRectByRect(optionsBounds, target)) {
              buttonDetails.push({
                x,
                y,
                start: seriesDatum[0],
                end: seriesDatum[1],
                deviceId: seriesDatum[5],
                scheduleId: seriesDatum[6],
                comments: seriesDatum[7],
              });
            }
          }
          setOptionsData(buttonDetails);
        }
      });
    }
  }, [getChart]);

  useEffect(() => {
    const chart = getChart();
    if (scheduleData) {
      chart.setOption({
        xAxis: [
          {
            id: 'xAxisSchedule',
            position: 'top',
            axisLabel: {
              fontWeight: 'bold',
              formatter: (function(value) {
                return `${moment(value).format('MMM')}\n\n${moment(value).format('DD')}`;
              })
            },
            min: getTimelineRange('backwards'),
            max: getTimelineRange('forwards'),
            // These 3 combined give a 1-week gap between date ticks
            splitNumber: daysPerWeek * 2,
            minInterval: secondsPerDay,
            maxInterval: secondsPerDay * daysPerWeek,
          },
        ],
        yAxis: [
          {
            id: 'yAxisSchedule',
            inverse: false,
            data: scheduleData.names.map((item) => item).toReversed(),
          },
          {
            id: 'yAxisHistory',
            min: 0,
            max: scheduleData.names.length,
          },
        ],
        series: [
          {
            id: 'yAxisScheduleData',
            yAxisId: 'yAxisSchedule',
            renderItem: renderItem,
            encode: {
              x: [0, 1],
              y: 0,
            },
            data: scheduleData.schedules,
          },
        ],
      });
    }
  }, [scheduleData]);

  useEffect(() => {
    if (groupId) {
      fetchHistory({ id: groupId });
    }
  }, [fetchHistory, groupId]);

  useEffect(() => {
    if (groupId) {
      fetchSchedule({ id: groupId });
    }
  }, [fetchSchedule, groupId]);

  function renderItem(params, api) {
    const timeSpan = [api.value(0), api.value(1)];
    const start = api.coord([timeSpan[0], api.value(4)]);
    const end = api.coord([timeSpan[1], api.value(4)]);
    const size = api.size([0, 1]);
    const height = size[1] * (swimlaneHeightProportion - totalVerticalSpacing);
    const fill = api.value(2);
    const text = api.value(3);
    const rect = clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );
    return rect && {
      type: 'rect',
      transition: ['shape'],
      shape: rect,
      name: text.toLowerCase(),
      // https://echarts.apache.org/v4/en/option.html#graphic.elements-text.style.font
      style: {
        text: text,
        // font: '1em "STHeiti", sans-serif',
        textAlign: 'center',
        fill: fill,
      },
    };
  }

  return (<>
    <div
      ref={containerRef}
      style={{ width: `${chartWidth}px` }}
    >
      <ReactECharts
        ref={eChartsReact}
        option={initialOptions}
        style={{...echartStyle, ...style}}
        opts={echartOpts}
      />
      {optionsData && optionsData.map((coord) => <OptionsDropdown
        key={`${coord.y}-${coord.x}`}
        top={coord.y}
        left={coord.x}
        groupId={groupId}
        deviceId={coord.deviceId}
        scheduleId={coord.scheduleId}
        start={coord.start}
        end={coord.end}
        comments={coord.comments}
      />)}
    </div>
  </>);
}

export default PlantVisibilityTimeline;
