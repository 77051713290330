import axios from 'axios';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import ChainedBackend from "i18next-chained-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

// en doesn't exist for import. Stupid design decision.
// It is US English. Annoying design decision.
// Begin quote
// By default, Moment.js comes with English (United States) locale strings. If you need other
// locales, you can load them into Moment.js for later use.
// End quote
// // https://momentjs.com/docs/#/i18n/changing-locale/

// en-US doesn't exist for import. moment will fallback from en-US to en. Stupid design decision.
// https://github.com/moment/moment/issues/3624.

// Core locales
import 'moment/locale/es';

// Extended locales
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-nz';
import 'moment/locale/es-mx';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const backendOption = {
  loadPath: `${API_BASE_URL}/translations?locale={{lng}}`,
  parse: data => {
    return data;
  },
  request: async (options, url, payload, callback) => {
    axios.get(url).then((result) => {
      callback(null, {
        data: result.data,
        status: 200,
      });
    }).catch(() => {
      callback(null, {
        status: 500,
      });
    });
  },
};

export const i18nInitOptions = {
  partialBundledLanguages: true,
  backend: {
    backends: [
      HttpApi,
      HttpApi,
    ],
    backendOptions: [
      backendOption, // e.g. en for core file e.g. Archive is the same in all variations
      backendOption, // e.g. en-AU for extended e.g. Organisation for en-AU/Organization for en-US
    ],
  },
  debug: true, // Once this settles down, disable in production
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  translationStats: {
    sourceLng: 'en-AU',
    targetLngs: ['es-MX']
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ChainedBackend)
  .init(i18nInitOptions);

export function getDefaultI18nextInstance() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(ChainedBackend)
    .init(i18nInitOptions);
  return i18n;
}

export function cloner(original, newI18nInitOptions) {
  original.cloneInstance({
    newI18nInitOptions
  });
}

export default i18n;
