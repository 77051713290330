import { t } from 'i18next';

import { CALL_API } from '../../lib/apiMiddleware';

import {
  getOrganisation,
  getOrganisationStaleState,
} from './selectors';
import * as ACTION_TYPES from './types/ActionTypes';

export function selectActiveGroup(group) {
  return dispatch => {
    return dispatch({
      type: ACTION_TYPES.SELECT_ACTIVE_GROUP,
      payload: group.id,
      meta: { group },
    });
  };
}

export function requestBegin(response) {
  return {
    type: ACTION_TYPES.REQUEST_BEGIN,
    response
  };
}

export function requestFail(response) {
  return {
    type: ACTION_TYPES.REQUEST_FAIL,
    response
  };
}

export function receiveOrganisationList() {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_LIST
  };
}

export function fetchOrganisations() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/organisations',
      requestAction: requestBegin(),
      successAction: receiveOrganisationList(),
      errorAction: requestFail()
    });
  };
}

export function requestOrganisationGroups(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_GROUPS,
    organisation,
  };
}

export function receiveOrganisationGroups(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_GROUPS,
    organisation,
  };
}

export function organisationGroupsFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_GROUPS_FAILURE,
    organisation,
  };
}

export function fetchOrganisationGroups(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/groups',
      requestAction: requestOrganisationGroups(organisation),
      successAction: receiveOrganisationGroups(organisation),
      errorAction: organisationGroupsFailure(organisation),
    });
  };
}

export function requestGroup(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_BEGIN,
    group,
  };
}

export function receiveGroup(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP,
    group,
  };
}

export function groupFailure(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_FAIL,
    group,
  };
}

export function fetchGroup(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}`,
      requestAction: requestGroup(group),
      successAction: receiveGroup(group),
      errorAction: groupFailure(group),
    });
  };
}

export function requestCreateGroup(group) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_GROUP,
    group,
  };
}

export function receiveCreateGroup(group) {
  return {
    type: ACTION_TYPES.RECEIVE_CREATE_GROUP,
    group,
  };
}

export function createGroupFailure(group) {
  return {
    type: ACTION_TYPES.CREATE_GROUP_FAILURE,
    group,
  };
}

export function createGroup(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/groups/`,
      data: group,
      requestAction: requestCreateGroup(group),
      successAction: receiveCreateGroup(group),
      errorAction: createGroupFailure(group),
      successToast: 'Group created',
      // don't add errorToast, allow validation errors to be seen
    });
  };
}

export function requestUpdateGroup(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_BEGIN,
    group,
  };
}

export function receiveUpdateGroup(group, data) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_GROUP,
    group,
    data,
  };
}

export function updateGroupFailure(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_FAIL,
    group,
  };
}

export function updateGroup(group, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/groups/${group.id}`,
      data,
      requestAction: requestUpdateGroup(group),
      successAction: receiveUpdateGroup(group, data),
      errorAction: updateGroupFailure(group),
      successToast: 'Group updated',
      // don't add errorToast, allow validation errors to be seen
    });
  };
}

export function requestDeleteGroup(group) {
  return {
    type: ACTION_TYPES.REQUEST_DELETE_GROUP,
    group,
  };
}

export function receiveDeleteGroup(group) {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_GROUP,
    group,
  };
}

export function deleteGroupFailure(group) {
  return {
    type: ACTION_TYPES.DELETE_GROUP_FAILURE,
    group,
  };
}

export function deleteGroup(group, { force }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/groups/${group.id}`,
      data: { force },
      requestAction: requestDeleteGroup(group),
      successAction: receiveDeleteGroup(group),
      errorAction: deleteGroupFailure(group),
      successToast: 'Group removed',
      errorToast: {
        timeout: 10000
      },
    });
  };
}

export function requestGroupMembers(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_MEMBERS,
    group,
  };
}

export function receiveGroupMembers(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_MEMBERS,
    group,
  };
}

export function groupMembersFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_MEMBERS_FAILURE,
    group,
  };
}

export function fetchGroupMembers(group, options = {}) {
  const { includeChildren, sensorType, } = options;
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/members`,
      params: {
        include_children: includeChildren ? true : undefined,
        sensor_type: sensorType,
      },
      requestAction: requestGroupMembers(group),
      successAction: receiveGroupMembers(group),
      errorAction: groupMembersFailure(group),
    });
  };
}

export function requestGroupMembersAvailable(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_MEMBERS_AVAILABLE,
    group,
  };
}

export function receiveGroupMembersAvailable(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_MEMBERS_AVAILABLE,
    group,
  };
}

export function groupMembersAvailableFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_MEMBERS_AVAILABLE_FAILURE,
    group,
  };
}

export function fetchGroupMembersAvailable(group, canceller) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/available`,
      requestAction: requestGroupMembersAvailable(group),
      successAction: receiveGroupMembersAvailable(group),
      errorAction: groupMembersAvailableFailure(group),
      abortControllerId: canceller?.id
    });
  };
}

export function requestAddGroupUsers(group) {
  return {
    type: ACTION_TYPES.REQUEST_ADD_GROUP_USERS,
    group,
  };
}

export function receiveAddGroupUsers(group) {
  return {
    type: ACTION_TYPES.RECEIVE_ADD_GROUP_USERS,
    group,
  };
}

export function addGroupUsersFailure(group) {
  return {
    type: ACTION_TYPES.ADD_GROUP_USERS_FAILURE,
    group,
  };
}

export function addGroupUsers(group, userIds) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/groups/${group.id}/members`,
      // payload is a user id list
      data: { users: userIds },
      requestAction: requestAddGroupUsers(group),
      successAction: receiveAddGroupUsers(group),
      errorAction: addGroupUsersFailure(group),
    });
  };
}

export function requestDeleteGroupUsers(group) {
  return {
    type: ACTION_TYPES.REQUEST_DELETE_GROUP_USERS,
    group,
  };
}

export function receiveDeleteGroupUsers(group) {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_GROUP_USERS,
    group,
  };
}

export function deleteGroupUsersFailure(group) {
  return {
    type: ACTION_TYPES.DELETE_GROUP_USERS_FAILURE,
    group,
  };
}

export function deleteGroupUsers(group, userIds=[]) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/groups/${group.id}/members`,
      // payload is a user id list
      data: { users: userIds },
      requestAction: requestDeleteGroupUsers(group),
      successAction: receiveDeleteGroupUsers(group),
      errorAction: deleteGroupUsersFailure(group),
    });
  };
}

export function requestGroupAccess(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_ACCESS,
    group,
  };
}

export function receiveGroupAccess(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_ACCESS,
    group,
  };
}

export function groupAccessFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_ACCESS_FAILURE,
    group,
  };
}

export function fetchGroupAccess(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/access`,
      requestAction: requestGroupAccess(group),
      successAction: receiveGroupAccess(group),
      errorAction: groupAccessFailure(group),
    });
  };
}

export function requestAddGroupAccess(group) {
  return {
    type: ACTION_TYPES.REQUEST_ADD_GROUP_ACCESS,
    group,
  };
}

export function receiveAddGroupAccess(group) {
  return {
    type: ACTION_TYPES.RECEIVE_ADD_GROUP_ACCESS,
    group,
  };
}

export function addGroupAccessFailure(group) {
  return {
    type: ACTION_TYPES.ADD_GROUP_ACCESS_FAILURE,
    group,
  };
}

export function addGroupAccess(group, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/groups/${group.id}/access`,
      // payload is an access object list keyed under "users" or "groups"
      data,
      requestAction: requestAddGroupAccess(group),
      successAction: receiveAddGroupAccess(group),
      errorAction: addGroupAccessFailure(group),
    });
  };
}

export function addGroupAccessUsers(group, users) {
  return addGroupAccess(group, { users });
}

export function addGroupAccessGroups(group, groups) {
  return addGroupAccess(group, { groups });
}

export function requestDeleteGroupAccess(group) {
  return {
    type: ACTION_TYPES.REQUEST_DELETE_GROUP_ACCESS,
    group,
  };
}

export function receiveDeleteGroupAccess(group) {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_GROUP_ACCESS,
    group,
  };
}

export function deleteGroupAccessFailure(group) {
  return {
    type: ACTION_TYPES.DELETE_GROUP_ACCESS_FAILURE,
    group,
  };
}

export function deleteGroupAccess(group, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/groups/${group.id}/access`,
      // payload is an id list keyed under "users" or "groups"
      data,
      requestAction: requestDeleteGroupAccess(group),
      successAction: receiveDeleteGroupAccess(group),
      errorAction: deleteGroupAccessFailure(group),
    });
  };
}

export function deleteGroupAccessUsers(group, users) {
  return deleteGroupAccess(group, { users });
}

export function deleteGroupAccessGroups(group, groups) {
  return deleteGroupAccess(group, { groups });
}

export function requestAddGroupDevices(group) {
  return {
    type: ACTION_TYPES.REQUEST_ADD_GROUP_DEVICES,
    group,
  };
}

export function receiveAddGroupDevices(group) {
  return {
    type: ACTION_TYPES.RECEIVE_ADD_GROUP_DEVICES,
    group,
  };
}

export function addGroupDevicesFailure(group) {
  return {
    type: ACTION_TYPES.ADD_GROUP_DEVICES_FAILURE,
    group,
  };
}

export function addGroupDevices(group, deviceIds) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/groups/${group.id}/members`,
      // payload is a device id list
      data: { devices: deviceIds },
      requestAction: requestAddGroupDevices(group),
      successAction: receiveAddGroupDevices(group),
      errorAction: addGroupDevicesFailure(group),
    });
  };
}

export function requestDeleteGroupDevices(group) {
  return {
    type: ACTION_TYPES.REQUEST_DELETE_GROUP_DEVICES,
    group,
  };
}

export function receiveDeleteGroupDevices(group) {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_GROUP_DEVICES,
    group,
  };
}

export function deleteGroupDevicesFailure(group) {
  return {
    type: ACTION_TYPES.DELETE_GROUP_DEVICES_FAILURE,
    group,
  };
}

export function deleteGroupDevices(group, deviceIds=[]) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/groups/${group.id}/members`,
      // payload is a device id list
      data: { devices: deviceIds },
      requestAction: requestDeleteGroupDevices(group),
      successAction: receiveDeleteGroupDevices(group),
      errorAction: deleteGroupDevicesFailure(group),
    });
  };
}

export function requestGroupRuntime(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_RUNTIME,
    group
  };
}

export function receiveGroupRuntime(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_RUNTIME,
    group
  };
}

export function groupRuntimeFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_RUNTIME_FAILURE,
    group
  };
}

export function fetchGroupRuntime(group, { view='summary' }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/runtime`,
      params: {
        'view': view || undefined,
      },
      requestAction: requestGroupRuntime(group),
      successAction: receiveGroupRuntime(group),
      errorAction: groupRuntimeFailure(group),
    });
  };
}

export function requestOrganisationRuntime(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_RUNTIME,
    organisation
  };
}

export function receiveOrganisationRuntime(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_RUNTIME,
    organisation
  };
}

export function organisationRuntimeFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_RUNTIME_FAILURE,
    organisation
  };
}

export function fetchOrganisationRuntime(organisation, { view='summary' }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/runtime`,
      params: {
        'view': view || undefined,
      },
      requestAction: requestOrganisationRuntime(organisation),
      successAction: receiveOrganisationRuntime(organisation),
      errorAction: organisationRuntimeFailure(organisation),
    });
  };
}

export function requestCreateOrganisation() {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_ORGANISATION
  };
}

export function receiveCreateOrganisation(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_CREATE_ORGANISATION,
    organisation,
  };
}

export function createOrganisationFailure() {
  return {
    type: ACTION_TYPES.CREATE_ORGANISATION_FAILURE
  };
}

export function submitNewOrganisationDetails(details) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: '/organisations',
      data: details,
      requestAction: requestCreateOrganisation(),
      successAction: receiveCreateOrganisation(details),
      errorAction: createOrganisationFailure(),
      successToast: 'Organisation created',
      // don't add errorToast, allow validation errors to be seen
      // it's probably a subdomain already taken message
    });
  };
}

export function submitNewChildOrganisationDetails(parent_organisation_id, payload) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/organisations/${parent_organisation_id}/organisations`,
      data: payload,
      requestAction: requestCreateOrganisation(),
      successAction: receiveCreateOrganisation({ parent_organisation_id, ...payload }),
      errorAction: createOrganisationFailure(),
      successToast: 'Organisation created',
      // don't add errorToast, allow validation errors to be seen
      // it's probably a subdomain already taken message
    });
  };
}

function requestOrganisationTags(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TAGS,
    organisation,
  };
}

function receiveOrganisationTags(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TAGS,
    organisation,
  };
}

function organisationTagsFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_TAGS_FAILURE,
    organisation,
  };
}

export function fetchOrganisationTags(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/tags`,
      requestAction: requestOrganisationTags(organisation),
      successAction: receiveOrganisationTags(organisation),
      errorAction: organisationTagsFailure(organisation),
    });
  };
}

// add alias: preferences are tags
export const fetchOrganisationPreferences = fetchOrganisationTags;

function requestOrganisationTokens(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TOKENS,
    organisation,
  };
}

function receiveOrganisationTokens(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS,
    organisation,
  };
}

function organisationTokensFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_TOKENS_FAILURE,
    organisation,
  };
}

function requestOrganisationTokensCreate(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TOKENS_CREATE,
    organisation,
  };
}

function receiveOrganisationTokensCreate(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS_CREATE,
    organisation,
  };
}

function organisationTokensCreateFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_TOKENS_CREATE_FAILURE,
    organisation,
  };
}

function requestOrganisationTokensEdit(organisation, token, description) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TOKENS_EDIT,
    organisation,
    token,
    description,
  };
}

function receiveOrganisationTokensEdit(organisation, token, description) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS_EDIT,
    organisation,
    token,
    description,
  };
}

function organisationTokensEditFailure(organisation, token, description) {
  return {
    type: ACTION_TYPES.ORGANISATION_TOKENS_EDIT_FAILURE,
    organisation,
    token,
    description,
  };
}

function requestOrganisationTokensDelete(organisation, token) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_TOKENS_DELETE,
    organisation,
    token,
  };
}

function receiveOrganisationTokensDelete(organisation, token) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_TOKENS_DELETE,
    organisation,
    token,
  };
}

function organisationTokensDeleteFailure(organisation, token) {
  return {
    type: ACTION_TYPES.ORGANISATION_TOKENS_DELETE_FAILURE,
    organisation,
    token,
  };
}

function requestStreamingConfigurations(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_STREAMING_CONFIGURATIONS,
    organisation,
  };
}

function receiveStreamingConfigurations(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS,
    organisation,
  };
}

function streamingConfigurationsFailure(organisation) {
  return {
    type: ACTION_TYPES.STREAMING_CONFIGURATIONS_FAILURE,
    organisation,
  };
}

function requestStreamingConfigurationsEdit(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_STREAMING_CONFIGURATIONS_EDIT,
    organisation,
  };
}

function receiveStreamingConfigurationsEdit(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS_EDIT,
    organisation,
  };
}

function streamingConfigurationsEditFailure(organisation) {
  return {
    type: ACTION_TYPES.STREAMING_CONFIGURATIONS_EDIT_FAILURE,
    organisation,
  };
}

function requestStreamingConfigurationsTest() {
  return {
    type: ACTION_TYPES.REQUEST_STREAMING_CONFIGURATIONS_TEST,
  };
}

function receiveStreamingConfigurationsTest() {
  return {
    type: ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS_TEST,
  };
}

function streamingConfigurationsTestFailure() {
  return {
    type: ACTION_TYPES.STREAMING_CONFIGURATIONS_TEST_FAILURE,
  };
}

function requestStreamingConfigurationsDelete(organisation, streamId) {
  return {
    type: ACTION_TYPES.REQUEST_STREAMING_CONFIGURATIONS_DELETE,
    organisation,
    streamId,
  };
}

function receiveStreamingConfigurationsDelete(organisation, streamId) {
  return {
    type: ACTION_TYPES.RECEIVE_STREAMING_CONFIGURATIONS_DELETE,
    organisation,
    streamId,
  };
}

function streamingConfigurationsDeleteFailure(organisation, streamId) {
  return {
    type: ACTION_TYPES.STREAMING_CONFIGURATIONS_DELETE_FAILURE,
    organisation,
    streamId,
  };
}

export function fetchOrganisationTokens(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/tokens`,
      requestAction: requestOrganisationTokens(organisation),
      successAction: receiveOrganisationTokens(organisation),
      errorAction: organisationTokensFailure(organisation),
    });
  };
}

export function createOrganisationToken(organisation, description) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/organisations/${organisation.id}/tokens`,
      data: { description },
      requestAction: requestOrganisationTokensCreate(organisation),
      successAction: receiveOrganisationTokensCreate(organisation),
      errorAction: organisationTokensCreateFailure(organisation),
      successToast: 'Token created',
      errorToast: 'Create failed',
    });
  };
}

export function editOrganisationToken(organisation, token, description) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/organisations/${organisation.id}/tokens/${token}`,
      data: { description },
      requestAction: requestOrganisationTokensEdit(organisation, token, description),
      successAction: receiveOrganisationTokensEdit(organisation, token, description),
      errorAction: organisationTokensEditFailure(organisation, token, description),
      successToast: 'Token edited',
      errorToast: 'Edit failed',
    });
  };
}

export function deleteOrganisationToken(organisation, token) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/organisations/${organisation.id}/tokens/${token}`,
      requestAction: requestOrganisationTokensDelete(organisation, token),
      successAction: receiveOrganisationTokensDelete(organisation, token),
      errorAction: organisationTokensDeleteFailure(organisation, token),
      successToast: 'Token removed',
      errorToast: 'Remove failed',
    });
  };
}

export function fetchStreamingConfiguration(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/streams`,
      requestAction: requestStreamingConfigurations(organisation),
      successAction: receiveStreamingConfigurations(organisation),
      errorAction: streamingConfigurationsFailure(organisation),
    });
  };
}

export function createStreamingConfiguration(organisation, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/organisations/${organisation.id}/streams`,
      data: data,
      requestAction: requestOrganisationTokensCreate(organisation),
      successAction: receiveOrganisationTokensCreate(organisation),
      errorAction: organisationTokensCreateFailure(organisation),
      successToast: 'Streaming Configuration created',
      errorToast: 'Create failed',
    });
  };
}

export function editStreamingConfiguration(organisation, streamId, data = {}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/organisations/${organisation.id}/streams/${streamId}`,
      data: data,
      requestAction: requestStreamingConfigurationsEdit(organisation),
      successAction: receiveStreamingConfigurationsEdit(organisation),
      errorAction: streamingConfigurationsEditFailure(organisation),
      successToast: 'Configuration edited',
      errorToast: 'Edit failed',
    });
  };
}

export function testStreamingConfiguration(organisation, streamId) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/streams/${streamId}/status`,
      requestAction: requestStreamingConfigurationsTest(),
      successAction: receiveStreamingConfigurationsTest(),
      errorAction: streamingConfigurationsTestFailure(),
      successToast: 'Configuration test submitted',
      errorToast: 'Test submission failed',
    });
  };
}

export function deleteStreamingConfiguration(organisation, streamId) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/organisations/${organisation.id}/streams/${streamId}`,
      requestAction: requestStreamingConfigurationsDelete(organisation, streamId),
      successAction: receiveStreamingConfigurationsDelete(organisation, streamId),
      errorAction: streamingConfigurationsDeleteFailure(organisation, streamId),
      successToast: 'Configuration removed',
      errorToast: 'Remove failed',
    });
  };
}

function requestOrganisationInfo(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_INFO,
    organisation
  };
}

function receiveOrganisationInfo(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_INFO,
    organisation
  };
}

export function organisationInfoFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_INFO_FAILURE,
    organisation
  };
}

export function fetchOrganisationIfStale(organisation={}) {
  return (dispatch, getState) => {
    const state = getState();
    // fetch org if it is currently stale
    if (getOrganisationStaleState(state)) {
      // pass full organisation object
      return fetchOrganisationWithId(getOrganisation(state, organisation.id))(dispatch);
    }
  };
}
export function fetchOrganisationWithId(organisation) {
  return dispatch => {
    dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}`,
      requestAction: requestOrganisationInfo(organisation),
      successAction: receiveOrganisationInfo(organisation),
      errorAction: organisationInfoFailure(organisation),
    });
  };
}

// organisation details update

function requestUpdateOrganisation(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_BEGIN,
    organisation
  };
}

function receiveUpdateOrganisation(organisation, details) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_ORGANISATION,
    organisation,
    details,
  };
}

function updateFailure(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_FAIL,
    organisation
  };
}

export function submitOrganisationDetails(organisation, details) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/organisations/${organisation.id}`,
      data: details,
      requestAction: requestUpdateOrganisation(organisation),
      successAction: receiveUpdateOrganisation(organisation, details),
      errorAction: updateFailure(organisation),
      successToast: 'Organisation updated',
      errorToast: 'Update failed'
    });
  };
}

function requestArchiveOrganisation(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ARCHIVE_ORGANISATION,
    organisation
  };
}

function receiveArchiveOrganisation(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ARCHIVE_ORGANISATION,
    organisation
  };
}

function archiveFailure(organisation) {
  return {
    type: ACTION_TYPES.ARCHIVE_ORGANISATION_FAILURE,
    organisation
  };
}

export function archiveOrganisation(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      data: {
        archived: true,
      },
      endpoint: `/organisations/${organisation.id}`,
      requestAction: requestArchiveOrganisation(organisation),
      successAction: receiveArchiveOrganisation(organisation),
      errorAction: archiveFailure(organisation),
      successToast: "Organisation Archived",
      errorToast: "Archive Failed"
    });
  };
}

// upload organisation logo
export function getLogoS3FileUrl(organisation, data = {}) {
  const {
    file_name = new Date().toISOString(), // default filename to current time
  } = data;
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/organisations/${organisation.id}/logo`,
      data: { file_name },
      requestAction: {
        organisation,
        type: ACTION_TYPES.REQUEST_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL
      },
      successAction: {
        organisation,
        type: ACTION_TYPES.RECEIVE_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL
      },
      errorAction: {
        organisation,
        type: ACTION_TYPES.ORGANISATION_LOGO_UPLOAD_S3_FILE_URL_FAILURE
      },
    });
  };
}

// fetch Organisation Logo
function requestOrganisationLogo(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_ORGANISATION_LOGO,
    organisation
  };
}

function receiveOrganisationLogo(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_LOGO,
    organisation
  };
}

export function organisationLogoFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_LOGO_FAILURE,
    organisation
  };
}

export function fetchOrganisationLogo(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/organisations/${organisation.id}/logo`,
      requestAction: requestOrganisationLogo(organisation),
      successAction: receiveOrganisationLogo(organisation),
      errorAction: organisationLogoFailure(organisation),
    });
  };
}

function requestGroupImpactConfig(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_IMPACT_CONFIG,
    group,
  };
}

function groupImpactConfigFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_IMPACT_CONFIG_FAILURE,
    group,
  };
}

function receiveGroupImpactConfig(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_IMPACT_CONFIG,
    group,
  };
}

export function fetchGroupImpactConfig(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/impactsettings`,
      requestAction: requestGroupImpactConfig(group),
      successAction: receiveGroupImpactConfig(group),
      errorAction: groupImpactConfigFailure(group),
    });
  };
}

function receiveAddGroupImpactConfig(group) {
  return {
    type: ACTION_TYPES.RECEIVE_ADD_GROUP_IMPACT_CONFIG,
    group,
  };
}

export function addGroupImpactConfig(group, config) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      data: config,
      endpoint: `/groups/${group.id}/impactsettings`,
      requestAction: requestGroupImpactConfig(group),
      successAction: receiveAddGroupImpactConfig(group),
      errorAction: groupImpactConfigFailure(group),
      successToast: 'Impact setting created.'
    });
  };
}

function receiveUpdateGroupImpactConfig(group, config, data) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_GROUP_IMPACT_CONFIG,
    group,
    config,
    data,
  };
}

export function updateGroupImpactConfig(group, config, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/groups/${group.id}/impactsettings/${config.id}`,
      data,
      requestAction: requestGroupImpactConfig(group),
      successAction: receiveUpdateGroupImpactConfig(group, config, data),
      errorAction: groupImpactConfigFailure(group),
      successToast: 'Impact setting updated.'
    });
  };
}

function receiveDeleteGroupImpactConfig(group, config) {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_GROUP_IMPACT_CONFIG,
    group,
    config,
  };
}

export function deleteGroupImpactConfig(group, config) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/groups/${group.id}/impactsettings/${config.id}`,
      requestAction: requestGroupImpactConfig(group),
      successAction: receiveDeleteGroupImpactConfig(group, config),
      errorAction: groupImpactConfigFailure(group),
      successToast: 'Impact setting deleted.'
    });
  };
}

function requestGroupImpactSummary(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_IMPACT_SUMMARY,
    group,
  };
}

function groupImpactSummaryFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_IMPACT_SUMMARY_FAILURE,
    group,
  };
}

function receiveGroupImpactSummary(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_IMPACT_SUMMARY,
    group,
  };
}

export function fetchGroupImpactSummary(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/impactsummary`,
      requestAction: requestGroupImpactSummary(group),
      successAction: receiveGroupImpactSummary(group),
      errorAction: groupImpactSummaryFailure(group),
    });
  };
}

function receiveGroupLocale(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_LOCALE,
    group,
  };
}
function groupLocaleFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_LOCALE_FAILURE,
    group,
  };
}

export function fetchGroupLocale(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/locale`,
      successAction: receiveGroupLocale(group),
      errorAction: groupLocaleFailure(group),
    });
  };
}

function receiveOrganisationLocale(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_ORGANISATION_LOCALE,
    organisation,
  };
}
function organisationLocaleFailure(organisation) {
  return {
    type: ACTION_TYPES.ORGANISATION_LOCALE_FAILURE,
    organisation,
  };
}

export function fetchOrganisationLocale(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `organisations/${organisation.id}/locale`,
      successAction: receiveOrganisationLocale(organisation),
      errorAction: organisationLocaleFailure(organisation),
    });
  };
}

function requestGroupTags(group) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_TAGS,
    group,
  };
}

function receiveGroupTags(group) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_TAGS,
    group,
  };
}

function GroupTagsFailure(group) {
  return {
    type: ACTION_TYPES.GROUP_TAGS_FAILURE,
    group,
  };
}

export function fetchGroupTags(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/tags`,
      requestAction: requestGroupTags(group),
      successAction: receiveGroupTags(group),
      errorAction: GroupTagsFailure(group),
    });
  };
}

export function requestGroupReportData(group, params) {
  return {
    type: ACTION_TYPES.REQUEST_GROUP_REPORT_DATA,
    group,
    params,
  };
}
export function groupReportDataFailure(group, params) {
  return {
    type: ACTION_TYPES.GROUP_REPORT_DATA_FAILURE,
    group,
    params,
  };
}
export function receiveGroupReportData(group, params) {
  return {
    type: ACTION_TYPES.RECEIVE_GROUP_REPORT_DATA,
    group,
    params,
  };
}
export function fetchGroupReportData(group, params) {
  const { type, filter, limit, view, order } = params;
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      params: {
        filter,
        limit,
        view,
        order,
      },
      endpoint: `/groups/${group.id}/reportdata/${type}`,
      requestAction: requestGroupReportData(group, params),
      successAction: receiveGroupReportData(group, params),
      errorAction: groupReportDataFailure(group, params),
    });
  };
}

function requestWebhooks(organisation) {
  return {
    type: ACTION_TYPES.REQUEST_WEBHOOKS,
    organisation,
  };
}

function receiveWebhooks(organisation) {
  return {
    type: ACTION_TYPES.RECEIVE_WEBHOOKS,
    organisation,
  };
}

function webhooksFailure(organisation) {
  return {
    type: ACTION_TYPES.WEBHOOKS_FAILURE,
    organisation,
  };
}

export function fetchWebhooks(organisation) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/hooks`,
      requestAction: requestWebhooks(organisation),
      successAction: receiveWebhooks(organisation),
      errorAction: webhooksFailure(organisation),
    });
  };
}

function receiveUpdateWebhooks(organisation, data) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_WEBHOOKS,
    organisation,
    data,
  };
}

export function updateWebhooks(organisation, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/hooks`,
      data,
      requestAction: requestWebhooks(organisation),
      errorAction: webhooksFailure(organisation),
      successAction: receiveUpdateWebhooks(organisation, data),
      successToast: 'Webhook is updated',
      // errorToast: 'Update failed',
    });
  };
}

function requestPlantVisibilityHistory(group) {
  return {
    type: ACTION_TYPES.REQUEST_PLANT_VISIBILITY_HISTORY,
    group,
  };
}

function receivePlantVisibilityHistory(group) {
  return {
    type: ACTION_TYPES.RECEIVE_PLANT_VISIBILITY_HISTORY,
    group,
  };
}

function plantVisibilityHistoryFailure(group) {
  return {
    type: ACTION_TYPES.PLANT_VISIBILITY_HISTORY_FAILURE,
    group,
  };
}

export function fetchPlantVisibilityHistory(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/members/history`,
      requestAction: requestPlantVisibilityHistory(group),
      successAction: receivePlantVisibilityHistory(group),
      errorAction: plantVisibilityHistoryFailure(group),
    });
  };
}

function requestPlantVisibilitySchedule(group) {
  return {
    type: ACTION_TYPES.REQUEST_PLANT_VISIBILITY_SCHEDULE,
    group,
  };
}

function receivePlantVisibilitySchedule(group) {
  return {
    type: ACTION_TYPES.RECEIVE_PLANT_VISIBILITY_SCHEDULE,
    group,
  };
}

function plantVisibilityScheduleFailure(group) {
  return {
    type: ACTION_TYPES.PLANT_VISIBILITY_SCHEDULE_FAILURE,
    group,
  };
}

export function fetchPlantVisibilitySchedule(group) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/groups/${group.id}/members/schedules`,
      requestAction: requestPlantVisibilitySchedule(group),
      successAction: receivePlantVisibilitySchedule(group),
      errorAction: plantVisibilityScheduleFailure(group),
    });
  };
}

function receiveUpdatePlantVisibilitySchedule(group, device, schedule, data) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_PLANT_VISIBILITY_SCHEDULE,
    group,
    device,
    schedule,
    data,
  };
}

export function updatePlantVisibilitySchedule(group, device, schedule, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${device.id}/schedules/${schedule.id}`,
      data,
      requestAction: requestPlantVisibilitySchedule(group),
      errorAction: plantVisibilityScheduleFailure(group),
      successAction: receiveUpdatePlantVisibilitySchedule(group, device, schedule, data),
      successToast: t('screens.organisations.plant-visibility-timeline.toast.update-schedule-success', {
        deviceId: device.id,
        scheduleId: schedule.id,
      })
    });
  };
}
