export const getAlarmType = (attribute, alarmComparison, highAlarm, hasHighAlarm) => {
  // Originally handled in a giant nested ternary. Converted to function for clarity,
  // despite its ugliness.
  const lowType = 'low';
  const highType = 'high';
  const isHigh = highAlarm && hasHighAlarm;
  let alarmType;
  if (alarmComparison === 'lt') alarmType = isHigh ? `${lowType}-low` : lowType;
  else alarmType = isHigh ? `${highType}-high` : highType;
  return `${attribute}-${alarmType}-alarm`;
};
