import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDeviceLocale } from "../modules/equipment/actions";
import { fetchGroupLocale, fetchOrganisationLocale } from "../modules/organisation/actions";
import { getDeviceLocale } from "../modules/equipment/selectors";
import { getGroupLocale, getOrganisationLocale } from "../modules/organisation/selectors";
import { defaultCurrency, defaultLocale } from "../components/Internationalisation";

const defaultLocaleConfig = {
  "locale": defaultLocale,
  "currency": {
    "currency": defaultCurrency,
    "use_grouping": true
  }
};

export default function useLocale({ groupId, organisationId, deviceId }) {
  const dispatch = useDispatch();
  const locale = useSelector(state => {
    return deviceId ? getDeviceLocale(state, deviceId) :
      groupId ? getGroupLocale(state, groupId) :
        organisationId ? getOrganisationLocale(state, organisationId): null;
  });
  useEffect(() => {
    if(locale) return;
    deviceId && dispatch(fetchDeviceLocale({id: deviceId}));
    groupId && dispatch(fetchGroupLocale({id: groupId}));
    organisationId && dispatch(fetchOrganisationLocale({id: organisationId}));
  }, [groupId, organisationId, deviceId]);

  return { locale: locale || defaultLocaleConfig, currency: locale?.currency?.currency || defaultLocaleConfig.currency.currency };
}
