import { useEffect, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPlantVisibilitySchedule, updatePlantVisibilitySchedule } from '../actions';
import {
  getPlantVisibilityByType,
  getPlantVisibilityStateByType
} from '../selectors';

function useGroupSchedule(groupId, deviceId=null, scheduleId=null, { fetch = false } = {}) {
  const dispatch = useDispatch();

  const args = { groupId, visibilityType: 'plantVisibilitySchedule' };
  const groupSchedules = useSelector(state => getPlantVisibilityByType(state, args));
  const { loading, error, lastFetch } = useSelector(state => getPlantVisibilityStateByType(state, args)) || {};

  const fetchSchedule = useCallback(() => {
    if (!groupId) return;
    dispatch(fetchPlantVisibilitySchedule({ id: groupId }));
  }, [groupId, dispatch]);

  const updateSchedule = useCallback(async (data) => {
    await dispatch(updatePlantVisibilitySchedule({ id: groupId }, { id: deviceId }, { id: scheduleId }, data));
    await dispatch(fetchPlantVisibilitySchedule({ id: groupId }));
  }, []);

  useEffect(() => {
    if (fetch && groupId) {
      fetchSchedule();
    }
  }, [fetch, groupId]);

  return { loading, error, lastFetch, groupSchedules, fetchSchedule, updateSchedule };
}

export default useGroupSchedule;
