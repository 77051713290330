
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import BaseModal from './BaseModal';
import LoadingSpinner from './LoadingSpinner';

class FormModal extends Component {

  constructor(props) {
    super(props);
    const { children, form } = props;
    if (!form || !React.isValidElement(form)) {
      throw new Error('Form is not a single element');
    }
    if (typeof form.props.onSubmit !== 'function') {
      throw new Error('Form does not have a onSubmit attribute');
    }
    if (children && !React.isValidElement(children)) {
      throw new Error('Child is not a single element');
    }
    if (children && typeof children.props.onClick === 'function') {
      throw new Error('Child has onClick attribute, this will not be used');
    }
  }

  state = {
    submitting: false,
  };

  async submit(e, { close }) {
    this.setState({ submitting: true });
    try {
      await this.props.form.props.onSubmit(e);
      // close with a successful response
      this.setState({ submitting: false }, () => close(true));
    }
    catch (e) {
      // don't close
      this.setState({ submitting: false });
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  renderModal = ({ shown, close }) => {
    const { submitting } = this.state;
    const { t } = this.props;
    const {
      header = '',
      body,
      form,
      valid,
      cancelText = t('cancel', 'Cancel'),
      cancelButtonProps = {},
      confirmText = t('submit', 'Submit'),
      confirmButtonProps = {},
      resetText = t('reset', 'Reset'),
      resetButtonProps = {},
      onReset,
      size,
      className,
    } = this.props;
    return (
      <Modal show={shown} onHide={close} size={size} className={className}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        {body && (
          <Modal.Body>
            {body}
          </Modal.Body>
        )}
        <Modal.Body>
          {React.cloneElement(form, {
            onSubmit: e => this.submit(e, { close }),
            // disable form onSubmit handling when the form is actively submitting
            as: !submitting ? 'form' : 'div',
          })}
        </Modal.Body>
        <Modal.Footer>
          {submitting && <LoadingSpinner inline size={1} />}
          {onReset && <Button
            variant="outline-secondary"
            {...resetButtonProps}
            disabled={submitting}
            onClick={onReset}
          >
            {resetText}
          </Button>}
          <Button
            // defaults
            variant="outline-secondary"
            // given
            {...cancelButtonProps}
            // overridden
            disabled={submitting}
            // close with an unsuccessful response
            onClick={() => close()}
          >
            {cancelText}
          </Button>
          <Button
            // defaults
            variant="danger"
            // given
            {...confirmButtonProps}
            // overridden
            disabled={submitting || !valid}
            onClick={e => this.submit(e, { close })}
          >
            {confirmText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    return (
      <BaseModal
        renderModal={this.renderModal}
        onShow={this.props.onShow}
        onClose={this.props.onClose}
      >
        {this.props.children}
      </BaseModal>
    );
  }
}

export default withTranslation()(FormModal);
