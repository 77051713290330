import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Title from '../../../components/Title';
import BasicModal from '../../../components/BasicModal';

import { fetchDeviceInfo } from '../../equipment/actions';

import { getAlarmDevice } from '../selectors';
import { relativeDateFormatter } from '../../../components/table/formatters';
import AlarmPossibleCausesImages from './AlarmPossibleCausesImages';
import { AlarmCauses } from '../../equipment/components/DeviceAlarmCard';
import { Alert, Row } from 'react-bootstrap';

function AlarmPossibleCausesModal({
  device = {},
  alarm = {},
  fetchDeviceInfo,
  children,
  onShow,
}) {

  useEffect(() => {
    if (device && device.id && !device.serial) {
      fetchDeviceInfo({id: device.id});
    }
  }, [device.id]);

  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const possibleCauses = alarm.possible_causes;
  if (possibleCauses === null) return null;
  if (possibleCauses === undefined) return null;
  if (possibleCauses.length === 0) return null;
  const titles = possibleCauses.map(({ title }) => title);

  const handleChildClick = (selectedIndex) => setIndex(selectedIndex);

  const resetIndex = () => setIndex(0);

  return (
    <BasicModal
      size="lg"
      header={(
        <Title title={t(`components.equipment.possible-causes.title.${titles[index]}`)} />
      )}
      subHeader={(
        <div>
          <p>
            {[
              alarm.site_name,
              alarm.site_subarea_name,
              alarm.equipment_name
            ].filter(Boolean).join(' - ')}
          </p>
          <p>
            <Link to={`/equipment/${alarm.device_id}`}>
              {device.serial}
            </Link>
            {' - '}
            {relativeDateFormatter(alarm.alarm_timestamp)}
          </p>
          <Row className="small-gutters">
            <AlarmCauses alarm={alarm} thisIndex={index} layout={'horizontal'} />
          </Row>
          <Alert variant="info">
            {t(`components.equipment.possible-causes.description.${possibleCauses[index].description}`)}
          </Alert>
        </div>
      )}
      body={(
        <div>
          <AlarmPossibleCausesImages
            alarm={alarm}
            handleChildClick={handleChildClick}
          />
        </div>
      )}
      onShow={onShow}
      onClose={resetIndex}
    >
      {children}
    </BasicModal>
  );
}

const mapStateToProps = (state, { alarm }) => {
  return {
    device: getAlarmDevice(state, alarm.id),
  };
};

const mapDispatchToProps = { fetchDeviceInfo };

export default connect(mapStateToProps, mapDispatchToProps)(AlarmPossibleCausesModal);
