import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { resendOTP, resetMfaRequired, validateOTP } from '../actions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import RootUnauthenticated from '../../../components/RootUnauthenticated';
import { getFormValues } from '../../../lib/utils';
import { getOtpToken } from '../selectors';
import { otpPattern } from '../components/MFAManager';

class ValidateOTP extends Component {

  constructor(props) {
    super(props);

    this.state = {
      submit: {},
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { otpCode } = getFormValues(e);
    const { validateOTP, otpToken } = this.props;

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    // submit form then handle feedback
    validateOTP(otpCode, otpToken)
      .then(() => {
        // if still displaying the same submission then update with success
        if (!this.unmounted) {
          this.setState(({ submit }) => {
            if (submit.submittedAt === submittedAt) {
              return { submit: { succeededAt: new Date() } };
            }
          });
        }
      })
      .catch((error) => {
        // if still displaying the same submission then update with failure
        if (!this.unmounted) {
          this.setState(({ submit }) => {
            if (submit.submittedAt === submittedAt) {
              return { submit: { error: error.message || 'Error' } };
            }
          });
        }
      });
  };

  handleResend = (override=false) => {
    const { resendOTP, otpToken } = this.props;

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    // submit form then handle feedback
    resendOTP(otpToken, override)
      .then(() => {
        // if still displaying the same submission then update with success
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { succeededAt: new Date() } };
          }
        });
      })
      .catch((error) => {
        // if still displaying the same submission then update with failure
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      });
  };

  handleBackToLogin = () => {
    const { resetMfaRequired } = this.props;
    resetMfaRequired();
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const { submit } = this.state;
    const { t } = this.props;

    return (
      <RootUnauthenticated title={t('screens.users.validate-otp.title', 'Enter One Time Password from SMS or email')}>
        <Form className="form-floating-labels" onSubmit={this.handleSubmit}>
          <Form.Group controlId="form__enter-otp">
            <Form.Control
              type="text"
              name="otpCode"
              placeholder={t('common.one-time-password', 'One Time Password')}
              required
              pattern={otpPattern}
              title={t('common.otp-description')}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            <Form.Label>{t('common.one-time-password', 'One Time Password')}</Form.Label>
          </Form.Group>
          <Button size="lg" variant="info" block type="submit" disabled={submit.submittedAt}>
            {submit.submittedAt ? (
              <LoadingSpinner size="1.5" />
            ) : (
              t('screens.users.validate-otp.enter-otp', 'Enter OTP')
            )}
          </Button>
          <br />
          <Button
            size="sm"
            variant="info"
            block
            type="submit"
            disabled={submit.submittedAt}
            onClick={() => this.handleResend()}
          >
            {submit.submittedAt ? (
              <LoadingSpinner size="1.5" />
            ) : (
              t('screens.users.validate-otp.resend-otp-config', 'Resend OTP via configured device')
            )}
          </Button>
          <Button
            size="sm"
            variant="info"
            block
            type="submit"
            disabled={submit.submittedAt}
            onClick={() => this.handleResend(true)}
          >
            {submit.submittedAt ? (
              <LoadingSpinner size="1.5" />
            ) : (
              t('screens.users.validate-otp.resend-otp-email', 'Resend OTP via email')
            )}
          </Button>
        </Form>
        <br />
        <Link
          to="/login"
          onClick={this.handleBackToLogin}>&larr; {t('screens.users.validate-otp.back-to-login-page', 'Back to login page')}
        </Link>
      </RootUnauthenticated>
    );
  }
}

const mapStateToProps = state => ({
  otpToken: getOtpToken(state),
});
const mapDispatchToProps = {validateOTP, resendOTP, resetMfaRequired};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ValidateOTP));
